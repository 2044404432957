@font-face {
    font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

@font-face {
    font-family: 'VeraHumana';
    src: url("/src/fonts/LibreBaskerville-Regular.otf");
}

.social-card {
    font-family: 'VeraHumana', sans-serif;
    background: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #EEF2F6;
    /*    border: solid 10px #FFF;*/
    position: relative;
}

.header {
    width: 1024px;
    /*content: url('images/header.png');*/
    position: absolute;
    top: 0;
}

.footer {
    width: 1024px;
    /*content: url('images/footer.png');*/
    position: absolute;
    bottom: 0;
}

.social-card .title {
    background-color: #000;
    padding: 60px 0px;
    width: 100%;
    opacity: .9;
    position: relative;
}

.social-card .title h1 {
    text-align: center;
    margin-left: 400px;
    margin-right: 100px;
    font-size: 2vw;
    line-height: 1.1em;
    color: #ffba05;
    display: none;
}

.logo {

    position: absolute;
    left: 340px;
    top: 180px;
}

.logo img {
    width: 300px;
}